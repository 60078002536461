.image_card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* cursor: pointer; */
  border-radius: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.image_card_image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .image_card_image {
  position: relative;
} */

.image_card_content {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: calc(100% - 20px); */
  /* padding: 0px; */
  padding: 10px;
  /* padding-top: 10px; */
  bottom: 0px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: var(--opacity-color); */
  background-color: var(--background-color);
  /* -webkit-backdrop-filter: blur(5px); */
  /* backdrop-filter: blur(5px); */
  /* height: 100px; */
  /* transition: all 0.3s ease-in-out; */
}

.image_card_overlay {
  opacity: 0;
  height: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-items: center;
  justify-content: center;
  width: 100%;
  /* padding: 10px; */
  top: 0px;
  left: 0px;
  background-color: var(--opacity-color);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: all 0.5s ease-in-out;
  transition-property: opacity, color;

  overflow: hidden;
}

.image_card_content h4, .image_card_overlay h4 {
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: none;
  margin: 0px;
  transition: all 0.3s ease-in-out;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .image_card_content hr {
  width: 80%;
  margin: 0px;
  margin-top: 3px;
  margin-bottom: 7px;
  background-color: var(--background-color) !important;
  color: var(--background-color) !important;
} */

.image_card_content_description {
  text-align: center;
  color: var(--background-color) !important;
  margin: 20px !important;
  margin-top: 0px !important;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.image_card_content p, .image_card_overlay p {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primary-color);
  text-decoration: none;
  margin: 0px;
}

.image_card_tag {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 7px 14px;
  margin: 0px;
  background-color: var(--secondary-color);
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 5px;
  z-index: 2;
}


.image_card:hover {
  /* border-radius: 10px; */
}

.image_card:hover .image_card_overlay {
  height: 100%;
  opacity: 1;
  /* padding: 10px; */
  /* width: calc(100% - 20px); */
  /* padding-bottom: 0px; */
  color: var(--background-color) !important;
}

.image_card:hover .image_card_overlay h4 {
  text-align: center;
  color: var(--background-color);
  margin: 20px;
}

/* .image_card:hover {
  transform: scale(1.05);
} */

/* .image_card:hover h4 {
  color: var(--secondary-color);
} */

/* .image_card_content:hover {
  height: 400px;
} */

/* .image_card:hover .image_card_content_description {
  height: 23px;
  margin-bottom: 5px;
} */

